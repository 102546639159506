import { Head } from 'components/Head';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import LogoDefault from 'assets/logo-default.svg';
import AppleStore from 'assets/apple_store.svg';
import PlayStore from 'assets/play_store.svg';
import Connect from 'assets/connect.svg';
import ConnectWithLocals from 'assets/connect_with_locals.svg';
import Explore from 'assets/explore.svg';
import OrderRides from 'assets/order_rides.svg';
import Mockup from 'assets/mockup.jpg';
import { Link } from 'react-router-dom';
import { Accordion } from 'components/Elements';
import { MainLayout } from 'components/Layout';

export const Landing = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <MainLayout title="Justknow" description="Connect Locally, Live Freely">
        <div className="londrina-solid-regular grid grid-cols-1 md:grid-cols-2 gap-4 mx-8 md:mx-32 mb-12">
          <div className="my-24">
            <div className="text-6xl font-bold mb-2">Connect Locally</div>
            <div className="text-6xl font-bold mb-2">Live Freely</div>
            <div className="text-xl mb-3">Chat, Ride, and Discover – All within Your Radius.</div>
            <div className="flex flex-row items-center space-x-2">
              <a href="">
                <img src={AppleStore} alt="App Store" className="h-12" />
              </a>
              <a href="">
                <img src={PlayStore} alt="Play Store" className="h-12" />
              </a>
            </div>
          </div>

          <div className="">
            <img src={Connect} alt="Connect locally" />
          </div>
        </div>

        <div className="mb-12 grid grid-cols-1 md:grid-cols-2 gap-8 mx-8 md:mx-24 md:items-center">
          <img src={Mockup} alt="JK Mockup" className="h-48 md:h-[60vh] mx-auto" />
          <div className="londrina-solid-black text-4xl md:text-6xl text-center">
            Order a ride and stay updated with your JustKnow feed.
          </div>
        </div>

        <div className="max-w-7xl mx-8 md:mx-32 mb-24 ">
          <div className="text-4xl font-bold text-center mb-4 londrina-solid-black">
            Our Services
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="">
              <div className="border bg-[#FFEFEA] rounded-2xl border-[#000000] p-8 mb-4">
                <img
                  src={ConnectWithLocals}
                  alt="Chat with nearby users"
                  className="mx-auto mb-8"
                />
                <div className="text-center font-bold text-xl londrina-solid-light">
                  Chat with nearby users
                </div>
              </div>
              <div className="text-2xl font-bold text-center londrina-solid-bold">
                CONNECT WITH LOCALS
              </div>
            </div>
            <div className="">
              <div className="border bg-[#FFEFEA] rounded-2xl border-[#000000] p-8 mb-4">
                <img
                  src={ConnectWithLocals}
                  alt="Chat with nearby users"
                  className="mx-auto mb-8"
                />
                <div className="text-center font-bold text-xl londrina-solid-light">
                  Flexible Boda Rides
                </div>
              </div>
              <div className="text-2xl font-bold text-center londrina-solid-bold">
                ORDER RIDES, YOUR WAY
              </div>
            </div>
            <div className="">
              <div className="border bg-[#FFEFEA] rounded-2xl border-[#000000] p-8 mb-4">
                <img
                  src={ConnectWithLocals}
                  alt="Chat with nearby users"
                  className="mx-auto mb-8"
                />
                <div className="text-center font-bold text-xl londrina-solid-light">
                  Discover Local Businesses{' '}
                </div>
              </div>
              <div className="text-2xl font-bold text-center londrina-solid-bold">
                EXPLORE WHAT’S NEARBY
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-8 md:mx-32 mb-12 ">
          <div className="text-4xl font-bold text-center mb-4 londrina-solid-black">
            Join Us Today
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <Link to="/register-your-business">
              <div className="bg-[#FFEFEA] rounded-2xl p-8 mb-4">
                <img src={Explore} alt="Chat with nearby users" className="mx-auto mb-8 h-48" />
                <div className="text-2xl font-bold text-center londrina-solid-bold">
                  Register Your Business
                </div>
              </div>
            </Link>
            <Link to="/become-a-rider">
              <div className="bg-[#FFEFEA] rounded-2xl p-8 mb-4">
                <img src={OrderRides} alt="Chat with nearby users" className="mx-auto mb-8 h-48" />
                <div className="text-2xl font-bold text-center londrina-solid-bold">
                  Become A Rider
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="mx-8 md:mx-32 mb-12">
          <div className="text-4xl font-bold text-start mb-4 londrina-solid-black">
            Why JustKnow
          </div>
          <Accordion title="Hyper-Local Connectivity">
            <div className="">
              JustKnow empowers you to connect with people in your immediate vicinity. Whether
              you're looking to make new friends, join local communities, or stay updated on what's
              happening around you, our app makes it easy to engage with those nearby
            </div>
          </Accordion>
          <Accordion title="Customizable Boda Rides">
            <div className="">
              With JustKnow, you're in control of your ride. Set your own starting price, track your
              ride in real-time, and share your journey with others for added security. No more
              haggling – just safe, affordable rides on your terms.
            </div>
          </Accordion>
          <Accordion title="Easy to Use">
            <div className="">
              JustKnow’s user-friendly interface ensures that you can navigate the app with ease.
              Whether you’re chatting, ordering a ride, or searching for a business, everything is
              just a tap away.
            </div>
          </Accordion>
          <Accordion title="Secure and Reliable">
            <div className="">
              Your safety is our priority. With robust security features, real-time tracking, and
              the ability to share your location, JustKnow ensures that your experiences are not
              only convenient but also secure.
            </div>
          </Accordion>
        </div>
      </MainLayout>
    </>
  );
};
